import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, navigate } from "gatsby";
import PageWrapper from "../components/PageWrapper";
import axiosConfig from '../axiosConfig';
import network from "../../constants/Network";
import GlobalContext from "../context/GlobalContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHospitalAlt } from '@fortawesome/free-solid-svg-icons';
import Cookies from 'js-cookie';
import { database, get, set, ref, push, onValue } from "../utils/firebase";

import imgF1 from "../assets/image/l2/png/featured-job-logo-1.png";
import iconD from "../assets/image/svg/icon-dolor.svg";
import iconB from "../assets/image/svg/icon-briefcase.svg";
import iconL from "../assets/image/svg/icon-location.svg";
import iconIR from "../assets/image/svg/icon-indrup.svg";
import ChatUI from "../components/ChatUI/ChatUI";

const DashboardPostedJobDetails = ({ location }) => {

  const params = new URLSearchParams(location.search);
  const jobId = params.get("id");
  const [job, setJob] = useState([]);
  const isMountedRef = useRef(false);
  const imageBaseUrl = `${network.serverip}/images/`;
  const [showMoreDescription, setShowMoreDescription] = useState(false);
  const [showMoreExperience, setShowMoreExperience] = useState(false);
  const { user } = useContext(GlobalContext);
  const gContext = useContext(GlobalContext);
  const [showMenuJobId, setShowMenuJobId] = useState(null);

  const toggleMenuButton = (id) => {
    setShowMenuJobId(showMenuJobId === id ? null : id)
  }

  const [userId, setUserId] = useState('');

  const placeholderImage = `${network.serverip}/images/No-job-Image-Placeholder.png`;

  useEffect(() => {
    if (user && user.id) {
      setUserId(user.id)
    }
  }, [user]);

  const fetchJobDetails = async () => {
    try {
      const response = await axiosConfig.post(`/jobdetails-forweb/${jobId}`, { userId: userId });
      if (response.data.success && isMountedRef.current) {
        setJob(response.data.data);
        console.log('This is the fetched job details:', response.data.data);
      } else if (!response.data.success) {
        if (isMountedRef.current) {
          setJob([]);
        }
        console.error('Failed to fetch job details');
      }
    } catch (error) {
      if (isMountedRef.current) {
        setJob([]);
      }
      console.error('Error fetching job details:', error);
    }
  };

  useEffect(() => {
    if (user && user.id) {
      setUserId(user.id)
    }
  }, [user]);

  useEffect(() => {
    if (!jobId) {
      console.log('Waiting for the job id');
      return;
    }

    isMountedRef.current = true;

    fetchJobDetails();

    return () => {
      isMountedRef.current = false;
    };
  }, [jobId, userId]);

  const styles = DashboardPostedJobDetailsStyling();

  return (
    <>
      <PageWrapper
        headerConfig={{
          button: "profile",
          isFluid: true,
          bgClass: "bg-default",
          reveal: false,
        }}
      >
        <div className="dashboard-main-container mt-27 mt-lg-27 pr-7">
          <div className="container border border-primary rounded m-5 pt-10 bg-white">

            <div className="row justify-content-center">

              <div className="col-xl-12 col-lg-12 mt-4 dark-mode-texts">
                <div className="mb-1">
                  <button style={{ border: 'none', backgroundColor: 'transparent' }} onClick={() => {
                    if (window.history.length > 1) {
                      navigate(-1);
                    } else {
                      navigate('/');
                    }
                  }} className="d-flex align-items-center ml-4">
                    {" "}
                    <i className="icon icon-small-left bg-white circle-40 mr-5 font-size-7 text-black font-weight-bold shadow-8"></i>
                    <span className="text-uppercase font-size-3 font-weight-bold text-gray">
                      Back
                    </span>
                  </button>
                </div>
              </div>

              <div className="col-xl-12 col-lg-12">

                <div className="pt-5 pl-sm-9 pl-5 pr-sm-9 pr-5 pb-4 border-bottom border-width-1 border-default-color light-mode-texts">
                  <div className="row">

                    <div className="col-12 pt-3 pb-3 d-flex justify-content-between align-items-start">
                      <div className="media align-items-center">
                        <button
                          onClick={() => gContext.toggleImageViewModal(job?.image)}
                          style={styles.imageViewButton}
                        >
                          <div className="square-72 d-flex align-items-center rounded-3 mr-8" style={styles.imageContainer}>
                            <img
                              src={job && job.image ? imageBaseUrl + job.image : placeholderImage}
                              alt=""
                              style={styles.jobImage}
                            />
                          </div>
                        </button>
                        <div>
                          <h3 className="font-size-6 mb-0">
                            {job?.title || ''}
                            <span className="text-success ml-5">
                              {job?.featured_job === 1 ? '(Featured Job)' : ''}
                            </span>
                          </h3>
                          <p className="font-size-4 text-gray line-height-2">
                            <span className="mr-2">
                              <FontAwesomeIcon icon={faHospitalAlt} />
                            </span>
                            {job?.institution?.title || ''}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  {job?.posted_by?.name && (
                    <div className="w-100 mt-2 mb-2 d-flex">
                      <div className="flex-shrink-0">
                        Posted by:
                      </div>
                      <div className="flex-grow-1 ml-3">
                        <p className="font-size-4 text-gray mb-0">
                          {`${job.posted_by.name}`}
                        </p>
                      </div>
                    </div>
                  )}

                  {job?.created_at && (
                    <div className="w-100 mt-2 mb-2 d-flex">
                      <div className="flex-shrink-0">
                        Posted on:
                      </div>
                      <div className="flex-grow-1 ml-3">
                        <p className="font-size-4 text-gray mb-0">
                          {job?.created_at ? `${new Date(job.created_at).toLocaleString('default', {
                            day: '2-digit',
                            month: 'long',
                            year: 'numeric'
                          })}`
                            : ''}
                        </p>
                      </div>
                    </div>
                  )}

                  {job?.job_expiry_date && (
                    <div className="w-100 mt-2 mb-2 d-flex">
                      <div className="flex-shrink-0">
                        Expires on:
                      </div>
                      <div className="flex-grow-1 ml-3">
                        <p className="font-size-4 text-gray mb-0">
                          {job?.job_expiry_date ? `${new Date(job.job_expiry_date).toLocaleString('default', {
                            day: '2-digit',
                            month: 'long',
                            year: 'numeric'
                          })}`
                            : ''}
                        </p>
                      </div>
                    </div>
                  )}

                </div>
                <div className="job-details-content pt-8 pl-sm-9 pl-6 pr-sm-9 pr-6 pb-10 border-bottom border-width-1 border-default-color light-mode-texts">
                  <div className="row mb-7">
                    <div className="col-md-4 mb-md-0 mb-6">
                      <div className="media justify-content-md-start">
                        <div className="image mr-5">
                          <img src={iconB} alt="" />
                        </div>
                        <p className="font-weight-semibold font-size-5 text-black-2 mb-0">
                          {job?.job_type?.title || 'Job type not available'}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-4 mb-md-0 mb-6">
                      <div className="media justify-content-md-start">
                        <div className="image mr-5">
                          <img src={iconL} alt="" />
                        </div>
                        <p>
                          <span className="font-weight-semibold font-size-5 text-black-2 mb-0">
                            {job?.location?.title || 'Location not available'}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="col-md-4 mb-md-0 mb-6">
                      <div className="media justify-content-md-start">
                        <p>
                          <span className="font-weight-semibold font-size-5 text-black-2 mb-0">
                            {job?.state?.title ? `State: ${job.state.title}` : ''}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4 mb-lg-0 mb-10">
                      <div className="">
                        <span className="font-size-4 d-block mb-4 font-weight-bold text-gray">
                          Category
                        </span>
                        <p className="font-size-4 text-black-2 mb-9 font-weight-semibold">
                          {job?.job_category?.title || 'Category not available'}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-4 mb-lg-0 mb-10">
                      <div className="">
                        <span className="font-size-4 d-block mb-4 font-weight-bold text-gray">
                          Qualification
                        </span>
                        <p className="font-size-4 text-black-2 mb-9 font-weight-semibold">
                          {job?.qualification?.title || 'Qualification not available'}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-4 mb-lg-0 mb-10">
                      <div className="">
                        <span className="font-size-4 d-block mb-4 font-weight-bold text-gray">
                          Experience in Years
                        </span>
                        <p className="font-size-4 text-black-2 mb-0 font-weight-semibold">
                          {job && job.experience_in_months !== undefined
                            ? [`${(job.experience_in_months / 12).toFixed(1)} year(s)`]
                            : ['Experience in years not available']
                          }
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 mb-lg-0 mb-10">
                      <div className="tags">
                        <p className="font-size-4 text-gray font-weight-bold mb-3">
                          Skills
                        </p>
                        <ul className="d-flex list-unstyled flex-wrap pr-sm-25 pr-md-0">
                          {job && job.skills && job.skills.length > 0 ? (
                            job.skills.map((skill, index) => (
                              <li key={index} className="bg-regent-opacity-15 mr-3 h-px-33 text-center flex-all-center rounded-3 px-5 font-size-4 font-weight-semibold text-black-2 mt-2">
                                {skill.title}
                              </li>
                            ))
                          ) : (
                            <li className="d-block font-size-4 text-black-2 mt-2">Skills not available</li>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="job-details-content pt-8 pl-sm-9 pl-6 pr-sm-9 pr-6 pb-10 border-bottom border-width-1 border-default-color light-mode-texts">
                  <div className="row">
                    <div className="col-xl-12 col-md-12">
                      <div className="">
                        <p className="mb-4 font-size-4 text-gray">
                          Description
                        </p>
                        <p className="font-size-4 text-black-2 mb-7" style={{ textAlign: 'justify' }}>
                          {job && job.description ? (
                            <span>
                              {(showMoreDescription || job.description.length <= 150)
                                ? `${job.description} `
                                : `${job.description.slice(0, 150)}... `}
                              {job.description.length > 150 && (
                                <button onClick={() => setShowMoreDescription(!showMoreDescription)} style={styles.showMoreButton}>
                                  {showMoreDescription ? 'Show less' : 'Show more'}
                                </button>
                              )}
                            </span>
                          ) : (
                            <span>Description not available</span>
                          )}
                        </p>
                      </div>
                      <div className="">
                        <p className="mb-4 font-size-4 text-gray">
                          Experience
                        </p>
                        <p className="font-size-4 text-black-2 mb-7" style={{ textAlign: 'justify' }}>
                          {job && job.experience ? (
                            <span>
                              {(showMoreExperience || job.experience.length <= 150)
                                ? `${job.experience} `
                                : `${job.experience.slice(0, 150)}... `}
                              {job.experience.length > 150 && (
                                <button onClick={() => setShowMoreExperience(!showMoreExperience)} style={styles.showMoreButton}>
                                  {showMoreExperience ? 'Show less' : 'Show more'}
                                </button>
                              )}
                            </span>
                          ) : (
                            <span>Experience not available</span>
                          )}
                        </p>
                      </div>

                      <div>
                        <Link
                          to={`/dashboard-job-edit/?id=${job.id}`}
                          className="btn btn-green text-uppercase btn-medium w-180 h-px-48 rounded-3 mr-4 mt-6"
                        >
                          Edit
                        </Link>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  );
};
export default DashboardPostedJobDetails;

function DashboardPostedJobDetailsStyling() {
  const styles = {
    showMoreButton: {
      display: 'inline-block',
      background: 'none',
      border: 'none',
      color: 'blue',
      cursor: 'pointer',
      textDecoration: 'underline',
      outline: 'none',
    },

    imageContainer: {
      backgroundColor: '#f5f5f5',
      aspectRatio: '4 / 3',
      overflow: 'hidden',
    },
    jobImage: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    skillItem: {
      whiteSpace: 'normal',
      overflowWrap: 'break-word',
      wordWrap: 'break-word',
      maxWidth: '100%',
    },
    imageViewButton: {
      outline: 'none',
      border: 'none',
      background: 'none',
      padding: '0',
    },
    openMenuIcon: {
      background: 'none',
      border: 'none',
      color: 'inherit',
      cursor: 'pointer',
      outline: 'none',
    },
    reportButton: {
      position: 'absolute',
      right: '2.9rem',
      width: 'auto',
      height: 'auto',
      background: 'white',
      border: '1px solid',
      borderRadius: '5px',
      paddingHorizontal: '6px',
      paddingVertical: '6px',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      outline: 'none',
    }
  };

  return styles;
}

